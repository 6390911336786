.lpWrap1 {
    width: 100%;
    min-height: 100vh;
}

.wrapper {
    max-width: 66rem;
    margin: auto;
    margin-top: 6rem;
}

.testimonialsPreviewWrapper {
    text-align: center;
    max-width: 55rem;
    margin: auto;
    margin-top: 8rem;
}

.testimonialsImg {
    width: 100%;
    height: auto;
}

.demoWrapper {
    position: relative;
    height: max-content;
}

.customizerGrid {
    margin-top: 12rem;
    display: grid;
    grid-template-columns: 25% 50%;
    column-gap: 16rem;

    width: 100%;
}

.demoFrame {
    width: 300px;
    height: 588px;
    position: absolute;
    z-index: 1;

    pointer-events: none;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='your_transparent.png', sizingMethod='scale');
}

.demo {
    width: 280px;
    height: 568px;
    margin: 10px;
    border-radius: 2rem;
}

.howWrapper {
    width: 100%;
    background-color: var(--primary-color);

    padding-top: 8rem;
    padding-bottom: 8rem;
}

.howIllustrationWrapper {
    margin: auto;
    width: 42rem;
}

.howIllustration {
    width: 42rem;
    height: auto;
}

.ddWrapper {
    max-width: 60rem;
    margin: auto;
    margin-top: 8rem;
}

.ddGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10rem;
    row-gap: 0rem;

    padding: none;
    margin-top: 4rem;

    width: 100%;
}

.ddPhoneImg {
    display: none;
}

.ddImg {
    display: block;
}

.inputError{
    border: none;
    outline: none;
    border: 1px solid var(--error-color);
    border-radius: 5px;
    font-family: "Red Hat Text";
    font-size: 18px;
    color: var(--text);

    width: 20rem;

    padding: 0.5rem 1rem 0.5rem 1rem;

    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.input:focus, .inputError:focus {
    border: solid 1px var(--primary-color);
}

.errorText{
    color: var(--error-color);
    font-family: "Red Hat Text", "Arial";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.05em;
    width: fit-content;
    margin: 0px;
}


.button {
    background-color: var(--primary-color);
    color: var(--text-inverted);
    font-family: "Red Hat Text";
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.025em;

    padding: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 8px;
    cursor: pointer;

    transition: smooth, 0.3s;
}

.button:hover{
    background-color: var(--secondary-color);
}

.button:hover{
    background-color: var(--secondary-color);
}


.ddGridSteps {
    display: grid;
    grid-template-columns: 9fr 13fr;
    column-gap: 2rem;
    row-gap: 0;
    padding: 0;
    margin: 0;

    width: 100%;
}

.datadrivenImg {
    width: 100%;
    height: auto;
    margin: auto;
    margin-top: 1rem;
}

.stepsList {
    display: grid;
    grid-template-rows: auto auto auto auto;
    row-gap: 0.7rem;
}

.stepsList > span {
    font-size: 17px;
    font-family: "Red Hat Text";
    font-weight: 500;
    color: var(--text);
}

@media screen and (max-width: 1000px) {
    .testimonialsPreviewWrapper {
        margin-top: 6rem;
    }
}

@media screen and (max-width: 800px) {
    .testimonialsPreviewWrapper {
        width: 90%;
        margin-top: 4rem;
    }

    .ddGrid {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        row-gap: 2rem;
        margin: auto;

        margin-top: 1rem;

        width: 90%;
    }

    .ddPhoneImg {
        display: block;
    }

    .ddImg {
        display: none;
    }

    .datadrivenImg {
        width: 90%;
        height: auto;
        margin: auto;
        margin-top: 1rem;
    }

    .ddGridSteps {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        row-gap: 2rem;
        margin: auto;
        margin-top: 1rem;

        width: 90%;
    }
}
