.wrapper {
    max-width: 60rem;
    margin: auto;
    margin-top: 6rem;
    width: 100%;

    margin-bottom: 6rem;
    text-align: center;
}

.wrapper > h1 {
    width: 100%;
}

.testiesGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2rem;
    row-gap: 0;

    width: 100%;
    height: fit-content;
    min-height: 12rem;

    margin-top: 6rem;
}

.card {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.25);

    width: 100%;
    height: 100%;
}

.contentGrid {
    text-align: center;
    padding: 2rem 2.5rem 0rem 2.5rem;

    display: grid;
    grid-template-rows: auto 4rem;
}

.bottom {
    height: 4.5rem;
    width: calc(100% - 5rem);

    position: absolute;
    bottom: 0;

    text-align: center;
}

.image {
    border: 2px solid white;
    border-radius: 50%;
    background-color: white;

    height: 5rem;
    width: 5rem;

    position: absolute;
    bottom: 5rem;
    left: calc(50% - 2.5rem);

    z-index: 101;
}

.name {
    font-size: 18px;
    font-family: "Red Hat Text";
    font-weight: 500;
    color: var(--primary-color);

    padding: 0;
    margin: 0;
}

.price {
    font-size: 32px;
    font-family: "Red Hat Text";
    font-weight: 700;
    color: var(--text);

    padding: 0;
    margin: 0;
    margin-top: 1rem;
}

.subtext {
    font-size: 14px;
    font-family: "Red Hat Text";
    font-weight: 400;
    color: #47465a;

    padding: 0;
    margin: 0;
    margin-top: 1rem;
}

.descriptionList {
    margin-top: 2rem;
    margin-bottom: 3rem;
    text-align: left;
}

.descriptionItem {
    display: grid;
    grid-template-columns: 1.3rem auto;
    margin-top: 0.5rem;
}

.description {
    font-size: 16px;
    font-family: "Red Hat Text";
    font-weight: 500;
    color: var(--text);
}

.button {
    background-color: var(--primary-color);
    color: var(--text-inverted);
    font-family: "Red Hat Text";
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.025em;

    padding: 0.7rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 8px;
    cursor: pointer;

    transition: smooth, 0.3s;
}

.buttonLight {
    border: solid 1px var(--text);
    color: var(--text);
    --secondary-color: grey;
    font-family: "Red Hat Text";
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.025em;

    padding: 0.7rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 8px;
    cursor: pointer;

    transition: smooth, 0.3s;
}

.button:hover, .buttonLight:hover{
    background-color: var(--secondary-color);
}

.bestValue {
    width: 100%;
    height: 1.7rem;
    position: absolute;
    top: -1.7rem;
    background-color: var(--primary-color);

    text-align: center;
}

.bestValue > p {
    font-size: 14px;
    font-family: "Red Hat Text";
    font-weight: 400;
    color: var(--text-inverted);

    padding: 0;
    margin: 0;
    margin-top: 0.3rem;
}

@media screen and (max-width: 800px) {
    .wrapper {
        width: 80%;
        margin-top: 3rem;
        text-align: center;
    }

    .testiesGrid {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        row-gap: 3rem;

        margin-top: 4rem;
    }
}
