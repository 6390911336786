.wrapper{
    position: fixed;
    
    width: 100%;
    bottom: 20px;
    z-index: 42000;
}

.innerWrapper{
    padding: 1rem;
    padding-top: 2rem;
    background-color: aliceblue;
    border: 1px solid var(--primary-color);
    align-items: center;

    margin: auto;

    width: calc(100vw - 180px);
    height: calc(30vh);

    text-align: center;
}

.grid{
    margin: auto;
    margin-top: 2rem;
    width: fit-content;
}

.link {
    color: var(--secondary-color);
    text-decoration: underline;
    margin: auto;
  }

.button {
    background-color: var(--primary-color);
    color: var(--text-inverted);
    font-family: "Red Hat Text";
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.025em;
  
    padding: 0.7rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border-radius: 8px;
    cursor: pointer;
  
    transition: smooth, 0.3s;
}
  
.button:hover {
    background-color: var(--secondary-color);
    /*background-color: var(--secondary-color);*/
}


@media only screen and (max-width: 670px) {
  .wrapper{
    bottom: 15px;
}

  .innerWrapper{
    margin: auto;
    width: calc(80%);
    height: fit-content;
  }
}