.headerWrapper {
    position: relative;

    padding-top: 10rem;
    padding-left: 8rem;
    padding-right: 8rem;
    height: calc(95vh - 3rem);
    max-height: 42rem;
    width: 100%;

    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.headerGrid {
    padding: 0;
    height: fit-content;
    margin: auto;
    max-width: 66rem;

    display: grid;
    grid-template-columns: 40% 60%;
    column-gap: 10rem;
}

.button {
    background-color: var(--primary-color);
    color: var(--text-inverted);
    font-family: "Red Hat Text";
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.025em;

    padding: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 8px;
    cursor: pointer;

    transition: smooth, 0.3s;
}

.button:hover{
    background-color: var(--secondary-color);
}

.headerImage {
    /*height: 80vh;
    max-height: 38rem;
    width: auto;*/
    width: 95%;
    min-width: 32rem;
    height: auto;
}

@media screen and (max-width: 1000px) {
    .headerWrapper {
        padding-top: 6rem;
        padding-left: 6rem;
        padding-right: 6rem;
        height: calc(95vh - 3rem);
        max-height: 100rem;
    }

    .headerGrid {
        max-width: 90vw;
        padding-left: 2rem;
        padding-right: 2rem;
        column-gap: 6rem;
        grid-template-columns: 60% 40%;
    }
}

@media screen and (max-width: 800px) {
    .headerWrapper {
        padding-top: 3rem;
        padding-left: 5%;
        padding-right: 5%;
        height: auto;
        max-height: 100rem;
    }

    .headerGrid {
        max-width: 90vw;
        width: 90%;
        padding-left: 2rem;
        padding-right: 2rem;
        row-gap: 2rem;
        grid-template-columns: 90%;
        grid-template-rows: auto auto;
    }

    .headerImage {
        /*height: 80vh;
        max-height: 38rem;
        width: auto;*/
        width: 95%;
        min-width: 0rem;
        height: auto;
    }
}
