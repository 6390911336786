.SPWrapper {
    position: relative;
    background-color: var(--primary-color);
    border-radius: 0 0 5rem 0;

    width: 100%;
}

.SPWrapperCorner {
    display: block;
    background-color: var(--primary-color);
    position: absolute;
    /*top: calc(100vh + 2rem);*/
    bottom: -5rem;
    overflow: hidden;

    height: 5rem;
    width: 5rem;
}

.SPWrapperCornerInner {
    display: block;
    position: absolute;
    bottom: -5rem;

    height: 10rem;
    width: 10rem;

    border-radius: 5rem;
    background-color: var(--background-color);
}

.SPContent {
    max-width: 60rem;
    margin: auto;
    margin-top: 4rem;
}

.sellingPointsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr));
    justify-content: left;
    column-gap: 5rem;
    margin: auto;
    width: 100%;
    padding: 0;
}

.SPItem {
    display: grid;
    grid-template-rows: 5rem auto auto;
    row-gap: 1rem;

    text-align: center;
    margin-top: 6rem;
    margin-bottom: 6rem;
}

.SPItem > img {
    align-items: baseline;
}

.SPImg {
    max-height: 5rem;
    width: auto;

    margin: auto;
}

@media screen and (max-width: 1000px) {
}

@media screen and (max-width: 800px) {
    .sellingPointsGrid {
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;
        column-gap: 0rem;
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 2rem;
        width: 80%;
    }

    .SPItem {
        grid-template-rows: 4rem auto auto;
        margin-top: 2rem;
        margin-bottom: 4rem;
    }

    .SPWrapper {
        border-radius: 0 0 0 0;
    }

    .SPWrapperCorner {
        display: none;
    }

    .SPWrapperCornerInner {
        display: none;
    }
}
