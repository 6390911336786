
.Wrapper{
  padding-top: 10rem;
  padding-left: 8rem;
  padding-right: 8rem;
  width: 100%;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.Content{
  height: fit-content;
  margin: auto;
  max-width: 66rem;
}

@media screen and (max-width: 1000px) {
  .Wrapper{
    padding-top: 6rem;
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .Content{
    padding-left: 2rem;
    padding-right: 2rem;
    column-gap: 6rem;
  }
}

@media screen and (max-width: 800px) {
  .Wrapper{
    padding-top: 3rem;
    padding-left: 5%;
    padding-right: 5%;
  }

  .Content{
    max-width: 90vw;
    width: 90%;
  }

}
