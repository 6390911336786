.wrapper {
    width: 100%;
}

.contactWrapper {
    width: 100%;
    text-align: center;
    margin: auto;
    margin-top: 6rem;
    margin-bottom: 4rem;
}

.button {
    background-color: var(--primary-color);
    color: var(--text-inverted);
    font-family: "Red Hat Text";
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.025em;

    padding: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 8px;
    cursor: pointer;

    transition: smooth, 0.3s;
}

.button:hover{
    background-color: var(--secondary-color);
}

.footerWrapper {
    width: 100%;
    margin-top: 6rem;
    background-color: var(--primary-color);
    padding-top: 2rem;
    padding-bottom: 3rem;
}

.footerGrid {
    margin-left: 6rem;
    margin-right: 6rem;
    max-width: 66rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 4rem;
    margin: auto;
}

.footerItem {
    text-align: left;
    width: fit-content;
    margin: auto;
}

.footerItem > p {
    color: white !important;
}

.vipirLogoWrapper {
    margin: auto;
    margin-top: 4rem;
    text-align: center;
}

.vipirLogo {
    height: 4rem;
    width: auto;
}

@media screen and (max-width: 800px) {
    .contactWrapper {
        margin-top: 2rem;
        margin-bottom: 0rem;
    }

    .footerGrid {
        width: 90%;
        grid-template-columns: auto;
        grid-template-rows: 1fr 1fr 1fr;
        row-gap: 2rem;
    }
}
