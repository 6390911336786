.wrapper {
    position: fixed;
    width: 100%;

    z-index: 111;
}

.wrapper * {
    color: var(--primary-color);
}

.innerWrapper {
    position: relative;
    height: 4rem;
    background-color: var(--background-color);
    /*background-image: linear-gradient(to bottom, var(--background-color), var(--background-color), #191824DD,  #19182400);
*/
    box-shadow: 0px 1px 8px rgba(88, 88, 88, 0.25);
}

.svgIcon {
    height: 3rem;
    width: auto;
}

.svgLogo {
    margin-top: 1rem;
    height: 2rem;
    width: auto;
}

.inline {
    position: absolute;
    top: 0rem;
    left: 2rem;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 0.7rem;
    max-width: fit-content;
    width: 10rem;
    align-items: center;
}

.buttonWrapper {
    display: block;
    margin-top: 0.5rem;
}

.button {
    background-color: var(--primary-color);
    color: var(--text-inverted);
    font-family: "Red Hat Text";
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.025em;

    padding: 0.7rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 8px;
    cursor: pointer;

    transition: smooth, 0.3s;
}

.button:hover{
    background-color: var(--secondary-color);
}

.links {
    position: absolute;
    top: 0rem;
    right: 2rem;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    column-gap: 1.5rem;
}

.linkWrapper {
    position: relative;
    width: fit-content;
    padding: 0.8rem;
    padding-top: 1.1rem;
    padding-bottom: 0.5rem;
}

.link {
    font-size: 18px;
    font-weight: 500;
    font-family: "Red Hat Text";
    margin: none;
    padding: none;
}

.linkUnderline {
    background-color: var(--primary-color);
    bottom: 0;
    height: 2.5px;
    left: 50%;
    position: absolute;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.linkWrapper:hover > .linkUnderline {
    width: 100%;
    left: 0;
}

.fullLinkUnderline {
    background-color: var(--primary-color);
    bottom: 0;
    height: 2.5px;
    left: 0;
    position: absolute;
    width: 100%;
}

.linkLogicWrapper {
    display: block;
}

.navCakeButtonWrapper {
    display: none;
    position: relative;
}

.navCakeButton {
    position: absolute;
    top: 0.2rem;
    right: -6rem;
    padding: 1rem;
}

.navCakeButtonImg {
    width: 1.3rem;
    height: auto;
}

.dropdownWrapper {
    position: absolute;
    top: 3rem;
    right: -5rem;

    display: grid;
    grid-template-columns: 8rem;
    row-gap: 2px;
    width: fit-content;

    background-color: var(--background-color);
    padding: 2px;
    border: 1px solid var(--secondary-color);
}

.linkWrapperDropdown {
    position: relative;
    width: 100%;
    text-align: center;

    padding: 0.8rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.linkWrapperDropdown * {
    color: var(--text);
}

.linkWrapperDropdown:hover,
.linkWrapperDropdown:focus-within {
    background-color: var(--background2-color);
}

.darkBg {
    background-color: var(--secondary-color) !important;
}

.darkBg * {
    color: var(--text-inverted);
}

@media only screen and (max-width: 670px) {
    .buttonWrapper {
        display: none;
        margin-top: 1.5rem;
    }

    .innerWrapper {
        height: 3.5rem;
    }

    .linkLogicWrapper {
        display: none;
    }

    .navCakeButtonWrapper {
        display: block;
    }

    .buttonWrapper {
        display: none;
    }
}
